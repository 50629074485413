import moment from 'moment'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { appConfig, appInit } from '../config'
import { appFailed, startPullAppStatus, stopPullAppStatus } from '../redux/actions'
import { AppState } from '../redux/appState'
import { changeLanguage, setAppStatus, setCreatingLive, setLandingPage, setNewPortalAccount, setRedirectedFromWTR, setSaveWtrFundsRedirection, setShowAppSidebar, setUserInsidePortal } from '../redux/slices/app'
import { Store } from '../redux/store'
import { AccountTradingTypes, __STORAGE } from './enums'
import { useAccounts, useLandingPage, useOrganization, usePlatforms, useSimplifyOnboardingCheck, useUserApplications } from './hooks'
import appRoutes, { dashboardPath, ibPath, profilePath } from "./routes"
import { AppInfo, AppropriatenessLevel, LoginResponse, WtrPostMessageType } from './types'
import { useEmailRedirect } from './useEmailRedirect'
import Utils from './utils'

export const useRedirect = () => {
    const store: AppState = useSelector(
        (state: Store) => state.App)

    const location = useLocation()
    const landingPage = useLandingPage()

    const {
        appStatus,
        liveAppCompleted,
        appropriatenessLevel,
        userProfile,
        liveAccounts,
        lastDeposit,
        creatingLive,
        uncompletedApplication,
        redirectedFromWTR,
        wtrFundsRoute,
    } = store

    const history = useHistory()
    const dispatch = useDispatch()
    const { isTMJP, isTMBM, isTMLC, isTMCY, isAU } = useOrganization()
    const { multiTypeTT } = usePlatforms()
    const validateEmail = useEmailRedirect()
    const { checkForSimplifiedOnboarding, checkForExSimplifyOnboarding } = useSimplifyOnboardingCheck()
    const wtrApp = Utils.wtrAppSession() || redirectedFromWTR

    const { hasApproved, incompleteApplication, hasApprovedCFD } = useUserApplications()

    const handleRedirectFromContactAndAddress = useCallback((accountTradingType?: AccountTradingTypes, skipPlatform?: boolean) => {
        if (!accountTradingType || accountTradingType === AccountTradingTypes.ForexAndCFDs) {
            if ((wtrApp && !multiTypeTT) || skipPlatform) {
                history.push(isTMBM || isTMLC
                    ? appRoutes.account.experience
                    : appRoutes.account.financialDetails)
            } else {
                history.push(appRoutes.account.selectPlatform)
            }
        } else if (accountTradingType === AccountTradingTypes.CashEquitiesASX) {
            history.push(appRoutes.account.chessSponsorship)
        } else if (accountTradingType === AccountTradingTypes.CashEquitiesJSE) {
            history.push(appRoutes.account.financialDetails)
        }
    }, [history, isTMBM, isTMLC, multiTypeTT, wtrApp])

    const handleRedirectByAppropriatenessLevel = useCallback((level?: AppropriatenessLevel) => {
        switch (level) {
            case 'REFER':
                history.push(appRoutes.account.individualRefer)
                break
            case 'FAIL':
                dispatch(appFailed())
                dispatch(setAppStatus('DENIED'))
                history.push(appRoutes.account.individualFailed)
                break
            default: ///?????
        }
    }, [dispatch, history])

    const handleRedirectFromFinancialDetails = useCallback((accountTradingType?: AccountTradingTypes) => {
        if (accountTradingType && accountTradingType === AccountTradingTypes.CashEquitiesJSE) {
            history.push(appRoutes.account.traderClassification)
        } else {
            history.push(appRoutes.account.experience)
        }
    }, [history])

    const handleSOredirects = useCallback((appInfo: AppInfo) => {
        const { formStep, completed } = appInfo
        if (completed) return

        switch (formStep) {
            case 0:
                history.push(appRoutes.account.phoneNumber)
                break
            case 1:
                if (wtrApp) {
                    history.push(appRoutes.account.forexConfirmation)
                } else {
                    history.push(appRoutes.account.selectPlatform)
                }
                break
            case 2:
                history.push(appRoutes.account.forexConfirmation)
                break
            case 3:
                history.push(appRoutes.account.simplified.address)
                break
            case 4:
                history.push(appRoutes.account.experience)
                break
            default:
                break
        }
    }, [history, wtrApp])

    const redirectToTermsAndCondsStep = useCallback(() => {
        switch (appropriatenessLevel) {
            case 'PASS':
                history.push(appRoutes.account.forexConfirmation)
                break
            case 'REFER':
            case 'FAIL':
                const userLoginInfo = Utils.loadEncryptedObject<LoginResponse>(__STORAGE.userLoginInfo)
                const lastLoginDate = moment(userLoginInfo?.last_login_date)
                const daysSinceLastLogin = moment().diff(lastLoginDate, 'days')
                history.push(daysSinceLastLogin <= 30
                    ? appRoutes.account.individualRefer
                    : appRoutes.account.forexConfirmation)
                break
            default:
                history.push(appRoutes.account.experience)
                break
        }
    }, [appropriatenessLevel, history])

    const handleTMCYRedirects = useCallback((appInfo: AppInfo, skipPlatform?: boolean) => {
        const { formStep } = appInfo
        if (!formStep) return history.push(appRoutes.account.contactAndAddress)
        switch (formStep) {
            case 1:
                history.push(appRoutes.account.contactAndAddress)
                break
            case 2:
                if (wtrApp || skipPlatform) {
                    history.push(appRoutes.account.financialDetails)
                } else {
                    history.push(appRoutes.account.selectPlatform)
                }
                break
            case 3:
                history.push(appRoutes.account.financialDetails)
                break
            case 4:
                history.push(appRoutes.account.financialDetailsSecondPage)
                break
            case 5:
                history.push(appRoutes.account.experience)
                break
            case 6:
                const isAdditionalConsentNeeded = userProfile && Utils.checkForAdditionalConsent(userProfile.country)
                if (isAdditionalConsentNeeded) {
                    history.push(appRoutes.account.consent)
                } else {
                    redirectToTermsAndCondsStep()
                }
                break
            case 7:
                redirectToTermsAndCondsStep()
                break
            default:
                history.push(appRoutes.account.verify.root)
                break
        }
        return
    }, [redirectToTermsAndCondsStep, history, userProfile, wtrApp])

    const redirectToRegisterFormStep = useCallback((updatedAppInfo: AppInfo | null = null) => {
        const uncompletedApp = updatedAppInfo || uncompletedApplication
        if (uncompletedApp) {
            const { completed, accountTradingTypes, formStep, appropriatenessLevel } = uncompletedApp
            if (completed) return
            const accountTradingType = Utils.retrieveAccTradingType(accountTradingTypes)
            const exSimplifyOnboarding = checkForExSimplifyOnboarding(uncompletedApp.platformAccountType)

            if (checkForSimplifiedOnboarding(userProfile?.country, uncompletedApp.platformAccountType)) {
                handleSOredirects(uncompletedApp)
                return
            }

            if (isTMJP) {
                if (!formStep) return history.push(appRoutes.account.clientJourney)
                switch (formStep) {
                    case 1:
                        history.push(appRoutes.account.clientJourney)
                        break
                    case 2:
                        history.push(appRoutes.account.subscriptionTiers)
                        break
                    case 3:
                        history.push(appRoutes.account.contactAndAddress)
                        break
                    case 4:
                        history.push(appRoutes.account.usaCitizenship)
                        break
                    case 5:
                        history.push(appRoutes.account.foreignExchangeBusiness)
                        break
                    case 6:
                        history.push(appRoutes.account.pepLegalConsent)
                        break
                    case 7:
                        history.push(appRoutes.account.financialDetails)
                        break
                    case 8:
                        history.push(appRoutes.account.experience)
                        break
                    case 9:
                        if (!appropriatenessLevel) {
                            history.push(appRoutes.account.appropriatenessTest)
                        } else {
                            history.push(appRoutes.account.forexConfirmation)
                        }
                        break
                    case 10:
                        history.push(appRoutes.account.forexConfirmation)
                        break
                    default:
                        history.push(appRoutes.account.verify.root)
                        break
                }
                return
            }

            if (isTMCY) {
                handleTMCYRedirects(uncompletedApp, exSimplifyOnboarding)
                return
            }

            if (isAU) {
                if (!formStep) return history.push(appRoutes.account.contactAndAddress)
                switch (formStep) {
                    case 1:
                        history.push(appRoutes.account.contactAndAddress)
                        break
                    case 2:
                        handleRedirectFromContactAndAddress(accountTradingType, exSimplifyOnboarding)
                        break
                    case 3:
                        if (accountTradingType === AccountTradingTypes.CashEquitiesASX) {
                            history.push(appRoutes.account.forexConfirmation)
                        } else {
                            history.push(appRoutes.account.financialDetails)
                        }
                        break
                    case 4:
                        history.push(appRoutes.account.experience)
                        break
                    case 5:
                        history.push(appRoutes.account.appropriatenessTest)
                        break
                    case 6:
                        const shouldGoToThe2ndApprTest = userProfile?.additionalAttributes && 
                            userProfile.additionalAttributes['hasAdditionalQsStep'] === "yes"
                        if (shouldGoToThe2ndApprTest) {
                            history.push(appRoutes.account.appropriatenessTestAdditional)
                        } else {
                            history.push(appRoutes.account.forexConfirmation)
                        }
                        break
                    case 7:
                        history.push(appRoutes.account.forexConfirmation)
                        break
                    default:
                        history.push(appRoutes.account.verify.root)
                        break
                }
                return
            }

            if (!formStep) {
                history.push(appRoutes.account.contactAndAddress)
            }
            else if (formStep === 1 || formStep === 0)
                history.push(appRoutes.account.contactAndAddress)
            else if (formStep === 2)
                handleRedirectFromContactAndAddress(accountTradingType, exSimplifyOnboarding)
            else if (formStep === 3)
                history.push(isTMBM || isTMLC
                    ? appRoutes.account.experience
                    : appRoutes.account.financialDetails)
            else if (formStep === 4)
                handleRedirectFromFinancialDetails(accountTradingType)
            else if (formStep === 5) {
                redirectToTermsAndCondsStep()
            }
        }
        else
            history.push(appRoutes.account.verify.root)
    }, [handleRedirectFromContactAndAddress,
        handleRedirectFromFinancialDetails,
        redirectToTermsAndCondsStep,
        handleSOredirects,
        handleTMCYRedirects,
        userProfile,
        history,
        isTMJP,
        isTMBM,
        isTMLC,
        isAU,
        checkForExSimplifyOnboarding,
        checkForSimplifiedOnboarding,
        isTMCY,
        uncompletedApplication])

    const continueSimplifiedFlow = useCallback(() => {
        // set completed to false because when level 1 is approved completed=true for some time until we set it back to false for level2
        const formStep = appStatus === 'LEVEL1_APPROVED'
            ? 3
            : uncompletedApplication.formStep || 3
        redirectToRegisterFormStep({ ...uncompletedApplication, completed: false, formStep })
    }, [appStatus, uncompletedApplication, redirectToRegisterFormStep])

    const { latestLiveAccount } = useAccounts()

    const pathnameWithoutTrailingSlash = location.pathname.replace(/\/+$/, '')
    const isOnCreatApppage = [
        appRoutes.account.createAccount.live,
    ].includes(pathnameWithoutTrailingSlash)
    const isOnIBPortal = location.pathname.indexOf(ibPath) !== -1

    const isOutside = location.pathname.indexOf(dashboardPath) === -1 &&
        location.pathname.indexOf(profilePath) === -1

    const isOnProfile = location.pathname.indexOf(profilePath) !== -1

    const redirectToLanding = useCallback(() => {
        if (isOutside && !isOnCreatApppage && !isOnIBPortal) {
            history.push(landingPage)
            dispatch(setLandingPage(undefined))
            dispatch(setUserInsidePortal(true))
        }
    }, [isOutside, isOnCreatApppage, isOnIBPortal, history, landingPage, dispatch])

    const redirectFundsMessage = (event: WtrPostMessageType) => {
        const lastVisited = wtrFundsRoute
        const fundsLocation = '/dashboard/funds/'
        switch (lastVisited) {
            case `${fundsLocation}deposit`:
                history.push(`${appRoutes.dashboard.funds.deposit}?account=${event.data.accountId}`)
                break
            case `${fundsLocation}withdraw`:
                history.push(`${appRoutes.dashboard.funds.withdraw}?account=${event.data.accountId}`)
                break
            case `${fundsLocation}transfer`:
                history.push(appRoutes.dashboard.funds.transfer)
                break
            case `${fundsLocation}history`:
                history.push(`${appRoutes.dashboard.funds.history}?account=${event.data.accountId}`)
                break
            case `${fundsLocation}accounts`:
                history.push(appRoutes.dashboard.funds.bankAccounts)
                break
            default:
                history.push(`${appRoutes.dashboard.funds.deposit}?account=${event.data.accountId}`)
                break
        }
    }

    const wtrPostMessageListener = async (event: WtrPostMessageType) => {
        await appInit
        if (event.origin === appConfig.WTR_URL) {
            dispatch(setRedirectedFromWTR(true))
            dispatch(setSaveWtrFundsRedirection(false))
            console.log('>>> message from WTR', event.data)

            if (event.data.lang) {
                dispatch(changeLanguage(event.data.lang))
            }

            switch (event.data.message) {
                case 'funds':
                    dispatch(setSaveWtrFundsRedirection(true))
                    redirectFundsMessage(event)
                    break
                case 'deposit':
                    dispatch(setSaveWtrFundsRedirection(true))
                    history.push(`${appRoutes.dashboard.funds.deposit}?account=${event.data.accountId}`)
                    break
                case 'reports':
                    history.push(`${appRoutes.dashboard.funds.tradeSummary}?account=${event.data.accountId}`)
                    break
                case 'openLive':
                    if (!await validateEmail()) break

                    if (incompleteApplication) {
                        redirectToRegisterFormStep()
                    } else if (hasApprovedCFD) {
                        history.push(appRoutes.dashboard.accounts.createLive)
                    } else {
                        history.push(appRoutes.account.createAccount.live)
                    }
                    break
                case 'openDemo':
                    history.push(appRoutes.dashboard.accounts.createDemo)
                    break
                case 'uploadDocuments':
                    history.push(appRoutes.account.verify.root)
                    break
                case 'completeOnboarding':
                    history.push(appRoutes.account.simplified.accountCreated)
                    break
                case 'status':
                    history.push(`${appRoutes.dashboard.funds.status}?status=${event.data.status}&transaction_id=${event.data.transactionId}`)
                    break
            }
        }
    }

    const redirectByStatus = useCallback(() => {
        if (isOnProfile) return
        const isSimplifiedOnboarding = checkForSimplifiedOnboarding(userProfile?.country, uncompletedApplication.platformAccountType)
        const isOnAccountCreatedLevelOnePage = location.pathname.indexOf(appRoutes.account.simplified.accountCreated) !== -1
        console.log('>> appStatus', appStatus)
        switch (appStatus) {
            case 'PENDING_REVIEW':
                dispatch(startPullAppStatus())
                dispatch(setShowAppSidebar(true))
                dispatch(setNewPortalAccount(true))

                if (isSimplifiedOnboarding) {
                    if (creatingLive) {
                        history.push(appRoutes.account.simplified.pendingApproval)
                    } else {
                        redirectToLanding()
                    }
                    break
                }

                if (creatingLive) {
                    history.push(latestLiveAccount
                        ? `${appRoutes.account.deposit}?account=${latestLiveAccount.account.accountNumber}`
                        : appRoutes.account.congrats)
                } else {
                    if (liveAccounts.length > 0 && !lastDeposit)
                        history.push(appRoutes.account.deposit)
                    else redirectToLanding()
                }
                break
            case 'PENDING':
            case 'PENDING_ID':
            case 'PENDING_ID_ADDRESS':
            case 'PENDING_PROPER_DOCUMENTS':
            case 'PENDING_E_VERIFICATION_RETRY':
            case 'PENDING_KYC':
            case 'MANUAL_DOCS':
                dispatch(setNewPortalAccount(true))
                dispatch(stopPullAppStatus())
                dispatch(setShowAppSidebar(true))

                if (isOnAccountCreatedLevelOnePage) {
                    break
                }

                if (isOutside && !isOnIBPortal) {
                    history.push(hasApproved
                        ? landingPage
                        : appRoutes.account.verify.root)
                }
                break
            case 'PENDING_ADDRESS':
                dispatch(setNewPortalAccount(true))
                dispatch(setShowAppSidebar(true))
                if (isOutside) {
                    history.push(hasApproved
                        ? landingPage
                        : appRoutes.account.verify.address)
                }
                break
            case 'INCOMPLETE':
                dispatch(setCreatingLive(true))
                dispatch(setNewPortalAccount(true))
                dispatch(setShowAppSidebar(true))
                if (isOutside) {
                    redirectToRegisterFormStep()
                }
                break
            case 'PENDING_APPROPRIATENESS_TEST':
            case 'PENDING_RG227':
            case 'DENIED':
            case 'FAILED':
                dispatch(setNewPortalAccount(true))
                dispatch(setShowAppSidebar(true))
                if (hasApproved) {
                    redirectToLanding()
                }
                else {
                    if (appropriatenessLevel === 'REFER') {
                        history.push(appRoutes.account.individualRefer)
                    } else {
                        history.push(appRoutes.account.individualFailed)
                    }
                }
                break
            case 'APPROVED':
                dispatch(stopPullAppStatus())
                dispatch(setCreatingLive(false))
                console.log('>> redirect from APPROVED status case handler')
                redirectToLanding()
                break
            case 'INTERNAL_TESTING':
            case 'PENDING_TRANSLATION':
            case 'PENDING_TRANSFER':
            case 'PENDING_NEW_TnC_ACCEPTANCE':
            case 'PENDING_IP_MISMATCH':
            case 'PENDING_BLURRY_POA':
            case 'PENDING_BLURRY_CROPPED_ID':
            case 'PENDING_DOB_MISMATCH':
            case 'DUPLICATE':
            case 'PENDING_ADDRESS_MISMATCH':
                redirectToLanding()
                break
            case 'PENDING_E_VERIFICATION':
                dispatch(startPullAppStatus())
                dispatch(setShowAppSidebar(true))
                redirectToLanding()
                break
            case 'LEVEL1_APPROVED':
                if (isOnAccountCreatedLevelOnePage) {
                    break
                }

                if (creatingLive) {
                    dispatch(stopPullAppStatus())
                    dispatch(setCreatingLive(false))
                    history.push(appRoutes.account.simplified.accountCreated)
                    break
                }

                if (uncompletedApplication?.completed) {
                    dispatch(stopPullAppStatus())
                    dispatch(setCreatingLive(false))
                    redirectToLanding()
                } else {
                    redirectToRegisterFormStep()
                }
                break
            default:
                redirectToLanding()
                break
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appStatus,
        appropriatenessLevel,
        creatingLive,
        dispatch,
        history,
        redirectToLanding,
        lastDeposit,
        liveAppCompleted,
        liveAccounts,
        hasApproved,
        hasApprovedCFD,
        store,
        userProfile])


    return {
        redirectByStatus,
        redirectToAccounts: redirectToLanding,
        redirectToRegisterFormStep,
        handleRedirectByAppropriatenessLevel,
        handleRedirectFromFinancialDetails,
        handleTMCYRedirects,
        wtrPostMessageListener,
        continueSimplifiedFlow,
    }
}