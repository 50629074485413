import { useCallback, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { IBApplication } from './../ib/utils/types'
import { AccountTradingTypes, PortalAccountDomain } from './enums'
import { useAppState, useUserApplications } from './hooks'
import routes from "./routes"
import { ApplicationType, Country, UserApplication } from './types'
import Utils from './utils'

const NO_STEP = 100
const { account, account: { ib } } = routes

export type OnboardingStep = {
    step: number
    url: string
    skipForSecondApp?: boolean
    skipForLevelOne?: boolean
    orgSkip?: PortalAccountDomain[]
}

type OnboardingFlow = 'ceAU' |
    'ceSA' |
    'cfdAU' |
    'cfd' |
    'cfdSimplified' |
    'cfdSimplifiedPAMMMaster' |
    'japan' |
    'cfdCorp' |
    'ceCorp'

const ceAU: Array<OnboardingStep> = [
    { step: -1, url: account.createAccount.live },
    { step: 0, url: account.personalInformation },
    { step: 1, url: account.contactAndAddress, skipForSecondApp: true },
    { step: 2, url: account.chessSponsorship },
    { step: 3, url: account.forexConfirmation },
]

const ceSA: Array<OnboardingStep> = [
    { step: -1, url: account.createAccount.live },
    { step: 0, url: account.personalInformation, skipForSecondApp: true },
    { step: 0, url: account.phoneNumber, skipForSecondApp: true },
    { step: 1, url: account.contactAndAddress, skipForSecondApp: true },
    { step: 2, url: account.financialDetails, skipForSecondApp: true },
    { step: 3, url: account.traderClassification },
    { step: 4, url: account.forexConfirmation },
]

const cfd: Array<OnboardingStep> = [
    { step: -1, url: account.createAccount.live },
    { step: 0, url: account.personalInformation, skipForSecondApp: true },
    { step: 0, url: account.phoneNumber, skipForSecondApp: true },
    { step: 1, url: account.contactAndAddress, skipForSecondApp: true },
    { step: 2, url: account.selectPlatform },
    { step: 3, url: account.pammQuestionnaire },
    { step: 4, url: account.financialDetails },
    { step: 5, url: account.financialDetailsSecondPage },
    { step: 6, url: account.experience },
    { step: 7, url: account.appropriatenessTest },
    { step: 8, url: account.appropriatenessTestAdditional },
    { step: 9, url: account.consent },
    { step: 10, url: account.forexConfirmation },
]

const cfdSimplified: Array<OnboardingStep> = [
    { step: -1, url: account.createAccount.live },
    { step: 0, url: account.personalInformation },
    { step: 0, url: account.phoneNumber },
    { step: 1, url: account.selectPlatform },
    { step: 2, url: account.forexConfirmation },
    { step: 3, url: account.simplified.address, skipForLevelOne: true },
    { step: 4, url: account.experience, skipForLevelOne: true },
]

const cfdSimplifiedPAMMMaster: Array<OnboardingStep> = [
    { step: -1, url: account.createAccount.live },
    { step: 0, url: account.personalInformation },
    { step: 1, url: account.selectPlatform },
    { step: 2, url: account.pammQuestionnaire },
    { step: 3, url: account.contactAndAddress },
    { step: 4, url: account.financialDetails, orgSkip: [PortalAccountDomain.TMBM, PortalAccountDomain.TMLC] },
    { step: 5, url: account.experience },
    { step: 6, url: account.forexConfirmation },
]

const japan: Array<OnboardingStep> = [
    { step: -1, url: account.createAccount.live },
    { step: 0, url: account.personalInformation },
    { step: 1, url: account.clientJourney },
    { step: 2, url: account.subscriptionTiers },
    { step: 3, url: account.contactAndAddress },
    { step: 4, url: account.usaCitizenship },
    { step: 5, url: account.foreignExchangeBusiness },
    { step: 6, url: account.pepLegalConsent },
    { step: 7, url: account.financialDetails },
    { step: 8, url: account.experience },
    { step: 9, url: account.appropriatenessTest },
    { step: 10, url: account.forexConfirmation },
]

const cfdCorp: Array<OnboardingStep> = [
    { step: -1, url: account.createAccount.live },
    { step: 0, url: account.personalInformation },
    { step: 1, url: account.corporate.corporateInformation, skipForSecondApp: true },
    { step: 2, url: account.corporate.contacts, skipForSecondApp: true },
    { step: 3, url: account.corporate.address, skipForSecondApp: true },
    { step: 4, url: account.corporate.placeOfBusiness, skipForSecondApp: true },
    { step: 5, url: account.corporate.regulation, skipForSecondApp: true },
    { step: 6, url: account.corporate.representative, skipForSecondApp: true },
    { step: 7, url: account.corporate.directors, skipForSecondApp: true },
    { step: 8, url: account.corporate.shareholders, skipForSecondApp: true },
    { step: 9, url: account.corporate.selectPlatform },
    { step: 10, url: account.corporate.financialDetails, skipForSecondApp: true },
    { step: 11, url: account.corporate.experience, skipForSecondApp: true },
    { step: 12, url: account.corporate.appropriatenessTest, skipForSecondApp: true },
    { step: 13, url: account.corporate.appropriatenessTestAdditional, skipForSecondApp: true },
    { step: 14, url: account.corporate.forexConfirmation },
]

const ceCorp: Array<OnboardingStep> = [
    { step: -1, url: account.createAccount.live },
    { step: 0, url: account.personalInformation },
    { step: 1, url: account.corporate.corporateInformation, skipForSecondApp: true },
    { step: 2, url: account.corporate.contacts, skipForSecondApp: true },
    { step: 3, url: account.corporate.address, skipForSecondApp: true },
    { step: 4, url: account.corporate.placeOfBusiness, skipForSecondApp: true },
    { step: 5, url: account.corporate.representative, skipForSecondApp: true },
    { step: 6, url: account.corporate.directors, skipForSecondApp: true },
    { step: 7, url: account.corporate.shareholders, skipForSecondApp: true },
    { step: 8, url: account.corporate.chessSponsorship },
    { step: 9, url: account.corporate.financialDetails, skipForSecondApp: true },
    { step: 10, url: account.corporate.experience, skipForSecondApp: true },
    { step: 11, url: account.corporate.forexConfirmation },
]

const ibSteps: Array<OnboardingStep> = [
    { step: -1, url: routes.register.ib },
    { step: 0, url: account.personalInformation },
    { step: 1, url: ib.q1 },
    { step: 2, url: ib.q2 },
    { step: 3, url: ib.legal },
    { step: 4, url: ib.accountCreated },
    { step: 5, url: ib.address },
    { step: 6, url: ib.pendingApproval },
    { step: 7, url: routes.account.verify.root },
]

export type OnboardingInit = {
    applicationType: ApplicationType
    tradingAccountType?: AccountTradingTypes
    domain?: PortalAccountDomain
    country?: Country
}

export interface OnboardingSteps {
    activeStep?: OnboardingStep
    activeIndex: number
    total: number
    steps: OnboardingStep[]
    prevStep: (app: Partial<UserApplication> | Partial<IBApplication>) => OnboardingStep | undefined
    nextStep: (app: Partial<UserApplication> | Partial<IBApplication>) => OnboardingStep | undefined
}

const useRetailOnboarding = (): OnboardingSteps => {
    const [flow, setFlow] = useState<OnboardingFlow>('cfd')
    const { levelOneApprovedApp, latestApplication, hasApproved } = useUserApplications()
    const { uncompletedApplication: app, userCountry,
        portalAccountDomain, onboardingDomain,
        userProfile, countries,
    } = useAppState()
    const location = useLocation()
    const domain = onboardingDomain || portalAccountDomain

    const accountType = app
        ? app.accountType
        : latestApplication
            ? latestApplication.type
            : 'demo'
    const accountTradingType = Utils.retrieveAccTradingType(app?.accountTradingTypes)

    const steps = useMemo(() => {
        const removeStep = (steps: Array<OnboardingStep>, url: string) => {
            return steps.filter(s => s.url !== url)
        }

        let result: Array<OnboardingStep> = []
        const removeTest = () => {
            result = removeStep(result, routes.account.appropriatenessTest)
        }
        const removeAdditionalTest = () => {
            result = removeStep(result, routes.account.appropriatenessTestAdditional)
        }
        const removeConsent = () => {
            result = removeStep(result, routes.account.consent)
        }
        const removeSecondFinDetails = () => {
            result = removeStep(result, routes.account.financialDetailsSecondPage)
        }
        const removeSmsVerification = () => {
            result = removeStep(result, routes.account.phoneNumber)
        }
        const country = userCountry ||
            userProfile?.country ||
            countries.find(c => c.id === app.originCountry)

        switch (accountType) {
            case 'corporate':
                switch (accountTradingType) {
                    case (AccountTradingTypes.ForexAndCFDs):
                        setFlow('cfdCorp')
                        result = cfdCorp
                        break
                    default:
                        setFlow('ceCorp')
                        result = ceCorp
                }
                break
            default:
                switch (accountTradingType) {
                    case (AccountTradingTypes.ForexAndCFDs):
                        const isPAMMMaster = app?.platformAccountType === 'PAMM_Master'
                        result = cfd
                        switch (domain) {
                            case 'AU':
                                setFlow('cfdAU')
                                removeConsent()
                                removeSecondFinDetails()
                                break
                            case 'TMJP':
                                setFlow('japan')
                                removeAdditionalTest()
                                result = japan
                                break
                            default:
                                if (country?.isSimplifyOnboarding) {
                                    if (!isPAMMMaster) {
                                        setFlow('cfdSimplified')
                                        result = cfdSimplified
                                        if (!levelOneApprovedApp)
                                            result = result.filter(s => !s.skipForLevelOne)
                                    }
                                    else {
                                        setFlow('cfdSimplifiedPAMMMaster')
                                        result = cfdSimplifiedPAMMMaster
                                    }
                                }
                                else {
                                    setFlow('cfd')
                                    removeTest()
                                    if (!country?.forcePhoneNumberValidate) removeSmsVerification()
                                    const isAdditionalConsentNeeded = Utils.checkForAdditionalConsent(country)
                                    if (!isAdditionalConsentNeeded) removeConsent()
                                    if (domain !== PortalAccountDomain.TMCY) removeSecondFinDetails()
                                }
                                removeAdditionalTest()
                        }
                        if (!isPAMMMaster)
                            result = removeStep(result, routes.account.pammQuestionnaire)
                        break
                    case (AccountTradingTypes.CashEquitiesASX):
                        setFlow('ceAU')
                        result = ceAU
                        break

                    case (AccountTradingTypes.CashEquitiesJSE):
                        setFlow('ceSA')
                        result = ceSA
                        if (!country?.forcePhoneNumberValidate) removeSmsVerification()
                        break

                    default:
                        setFlow('cfd')
                        result = cfd
                        break

                }

        }
        if (hasApproved) result = result.filter(s => !s.skipForSecondApp)
        result = result.filter(s => !(s.orgSkip && s.orgSkip.some(d => d === domain)))
        return result
    }, [accountTradingType, accountType, app.originCountry, app?.platformAccountType, countries, domain, hasApproved, levelOneApprovedApp, userCountry, userProfile?.country])

    const activeStep = useMemo(() => {
        const step = steps.find(s => s.url === location.pathname)
        return step
    }, [location.pathname, steps])

    const activeIndex = useMemo(() => {
        const step = steps.find(s => s.url === location.pathname)
        // set to NO_STEP to show stepper as filled if no path found, will handle all rejected/denied as final step
        const index = step ? steps.indexOf(step) : NO_STEP
        return index
    }, [location.pathname, steps])

    const total = useMemo(() => {
        return steps.length
    }, [steps])

    const prevStep = useCallback(() => {
        const prev = (inc = -1) => {
            return activeIndex > 0
                ? steps[activeIndex + inc]
                : undefined
        }
        switch (flow) {
            case "cfdAU":
                if (activeStep?.url === routes.account.forexConfirmation) {
                    const hasSecondTest = userProfile?.additionalAttributes && 
                        userProfile.additionalAttributes['hasAdditionalQsStep'] === "yes"
                    if (hasSecondTest) return prev()
                    else return prev(-2)
                }
                else return prev()
            default: return prev()
        }
    }, [activeIndex, activeStep, flow, steps, userProfile])

    const nextStep = useCallback(() => {
        return activeIndex !== NO_STEP
            ? steps[activeIndex + 1]
            : undefined
    }, [activeIndex, steps])

    return { activeStep, activeIndex, total, prevStep, nextStep, steps }

}

const useIBOnboarding = (): OnboardingSteps => {
    const steps = ibSteps
    const location = useLocation()

    const activeStep = useMemo(() => {
        return steps.find(s => s.url === location.pathname)
    }, [location.pathname, steps])

    const activeIndex = useMemo(() => {
        const step = steps.find(s => s.url === location.pathname)
        // set to NO_STEP to show stepper as filled if no path found, will handle all rejected/denied as final step
        const index = step ? steps.indexOf(step) : NO_STEP
        return index
    }, [location.pathname, steps])

    const total = useMemo(() => {
        return steps.length
    }, [steps])

    const prevStep = useCallback((app) => {
        return activeIndex > 0
            ? steps[activeIndex - 1]
            : undefined
    }, [activeIndex, steps])

    const nextStep = useCallback(() => {
        return activeIndex !== NO_STEP
            ? steps[activeIndex + 1]
            : undefined
    }, [activeIndex, steps])

    return { activeStep, activeIndex, total, prevStep, nextStep, steps }
}

const useVisualSteps = (): OnboardingSteps => {
    const { applicationToCreate } = useAppState()
    const ib = useIBOnboarding()
    const retail = useRetailOnboarding()
    const isIB = applicationToCreate === 'partner'
    return isIB ? ib : retail
}

export { useVisualSteps }


